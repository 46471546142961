var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1500px","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('update:dialogSearchProdutosMultiDeposito', false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('update:dialogSearchProdutosMultiDeposito', false)}},model:{value:(_vm.dialogSearchProdutosMultiDeposito),callback:function ($$v) {_vm.dialogSearchProdutosMultiDeposito=$$v},expression:"dialogSearchProdutosMultiDeposito"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1 tocs_gray_1"},[_vm._v(" Buscar Produto "),(_vm.deposito_descricao)?_c('span',[_vm._v(" - "+_vm._s(_vm.$tc("global.deposito") + ": " + _vm.deposito_descricao)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('update:dialogSearchProdutosMultiDeposito', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6"},[_c('v-text-field',_vm._g(_vm._b({ref:"search",staticClass:"mb-3",staticStyle:{"min-width":"350px"},attrs:{"loading":_vm.loadingSearch,"dense":"","outlined":"","error":_vm.error,"clearable":"","hide-details":"","autofocus":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',_vm.$attrs,false),_vm.$listeners)),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.produtos,"options":_vm.options,"item-key":'id' + '-' + 'deposito',"server-items-length":_vm.totalProdutos,"item-class":_vm.row_classes},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.selectProduto},scopedSlots:_vm._u([{key:"item.preco_minimo",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(item.moeda))]),_c('div',[_vm._v(_vm._s(_vm._f("guarani")(item.preco_minimo)))])]):_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(item.moeda))]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(item.preco_minimo)))])])]}},{key:"item.preco2",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(item.moeda))]),_c('div',[_vm._v(_vm._s(_vm._f("guarani")(item.preco2)))])]):_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(item.moeda))]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(item.preco2)))])])]}},{key:"item.preco3",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(item.moeda))]),_c('div',[_vm._v(_vm._s(_vm._f("guarani")(item.preco3)))])]):_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(item.moeda))]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(item.preco3)))])])]}},{key:"item.deposito1",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center"},on),[_c('div',[_vm._v(" "+_vm._s(item.deposito1 - item.reservado_deposito_1 - item.reservado_transferencia_deposito1)+" ")]),_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-information-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.reservado"))+": "+_vm._s(item.reservado_deposito_1))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$tc("global.reservado") + " " + _vm.$tc("global.transferencia"))+": "+_vm._s(item.reservado_transferencia_deposito1))])])]}},{key:"item.deposito2",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center"},on),[_c('div',[_vm._v(" "+_vm._s(item.deposito2 - item.reservado_deposito_2 - item.reservado_transferencia_deposito2)+" ")]),_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-information-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.reservado"))+": "+_vm._s(item.reservado_deposito_2))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$tc("global.reservado") + " " + _vm.$tc("global.transferencia"))+": "+_vm._s(item.reservado_transferencia_deposito2))])])]}},{key:"item.deposito3",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center"},on),[_c('div',[_vm._v(" "+_vm._s(item.deposito3 - item.reservado_deposito_3 - item.reservado_transferencia_deposito3)+" ")]),_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-information-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.reservado"))+": "+_vm._s(item.reservado_deposito_3))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$tc("global.reservado") + " " + _vm.$tc("global.transferencia"))+": "+_vm._s(item.reservado_transferencia_deposito3))])])]}},{key:"item.ultimo_custo",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v("G$")]),_c('div',[_vm._v(_vm._s(_vm._f("guarani")(item.ultimo_custo)))])]):_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v("G$")]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(item.ultimo_custo)))])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }