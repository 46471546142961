<template>
  <v-dialog
    v-model="dialogSearchProdutosMultiDeposito"
    @click:outside="$emit('update:dialogSearchProdutosMultiDeposito', false)"
    @keydown.esc="$emit('update:dialogSearchProdutosMultiDeposito', false)"
    width="1500px"
    scrollable
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        Buscar Produto
        <span v-if="deposito_descricao">
          -
          {{ $tc("global.deposito") + ": " + deposito_descricao }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="$emit('update:dialogSearchProdutosMultiDeposito', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-6">
        <v-text-field
          v-model="search"
          :loading="loadingSearch"
          dense
          outlined
          ref="search"
          style="min-width: 350px"
          class="mb-3"
          :error="error"
          clearable
          hide-details
          autofocus
          @click:clear="clearSearch"
          v-bind="$attrs"
          v-on="$listeners"
        >
        </v-text-field>

        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="produtos"
            :options.sync="options"
            :item-key="'id' + '-' + 'deposito'"
            :server-items-length="totalProdutos"
            class="data-tables"
            :item-class="row_classes"
            @click:row="selectProduto"
          >
            <template v-slot:item.preco_minimo="{ item }">
              <div
                v-if="item.moeda == 'G$'"
                class="d-flex align-center justify-space-between"
              >
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco_minimo | guarani }}</div>
              </div>
              <div v-else class="d-flex align-center justify-space-between">
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco_minimo | currency }}</div>
              </div>
            </template>
            <template v-slot:item.preco2="{ item }">
              <div
                v-if="item.moeda == 'G$'"
                class="d-flex align-center justify-space-between"
              >
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco2 | guarani }}</div>
              </div>
              <div v-else class="d-flex align-center justify-space-between">
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco2 | currency }}</div>
              </div>
            </template>
            <template v-slot:item.preco3="{ item }">
              <div
                v-if="item.moeda == 'G$'"
                class="d-flex align-center justify-space-between"
              >
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco3 | guarani }}</div>
              </div>
              <div v-else class="d-flex align-center justify-space-between">
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco3 | currency }}</div>
              </div>
            </template>

            <template v-slot:item.deposito1="{ item }" class="pa-0">
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="d-flex align-center justify-center">
                    <div>
                      {{
                        item.deposito1 -
                        item.reservado_deposito_1 -
                        item.reservado_transferencia_deposito1
                      }}
                    </div>
                    <v-icon size="small">mdi-information-variant </v-icon>
                  </div>
                </template>
                <span
                  >{{ $tc("global.reservado") }}:
                  {{ item.reservado_deposito_1 }}</span
                ><br />
                <span
                  >{{
                    $tc("global.reservado") + " " + $tc("global.transferencia")
                  }}: {{ item.reservado_transferencia_deposito1 }}</span
                >
              </v-tooltip>
            </template>

            <template v-slot:item.deposito2="{ item }" class="pa-0">
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="d-flex align-center justify-center">
                    <div>
                      {{
                        item.deposito2 -
                        item.reservado_deposito_2 -
                        item.reservado_transferencia_deposito2
                      }}
                    </div>
                    <v-icon size="small">mdi-information-variant </v-icon>
                  </div>
                </template>
                <span
                  >{{ $tc("global.reservado") }}:
                  {{ item.reservado_deposito_2 }}</span
                ><br />
                <span
                  >{{
                    $tc("global.reservado") + " " + $tc("global.transferencia")
                  }}: {{ item.reservado_transferencia_deposito2 }}</span
                >
              </v-tooltip>
            </template>
            <template v-slot:item.deposito3="{ item }" class="pa-0">
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="d-flex align-center justify-center">
                    <div>
                      {{
                        item.deposito3 -
                        item.reservado_deposito_3 -
                        item.reservado_transferencia_deposito3
                      }}
                    </div>
                    <v-icon size="small">mdi-information-variant </v-icon>
                  </div>
                </template>
                <span
                  >{{ $tc("global.reservado") }}:
                  {{ item.reservado_deposito_3 }}</span
                ><br />
                <span
                  >{{
                    $tc("global.reservado") + " " + $tc("global.transferencia")
                  }}: {{ item.reservado_transferencia_deposito3 }}</span
                >
              </v-tooltip>
            </template>

            <template v-slot:item.ultimo_custo="{ item }">
              <div
                v-if="item.moeda == 'G$'"
                class="d-flex align-center justify-space-between"
              >
                <div>G$</div>
                <div>{{ item.ultimo_custo | guarani }}</div>
              </div>
              <div v-else class="d-flex align-center justify-space-between">
                <div>G$</div>
                <div>{{ item.ultimo_custo | currency }}</div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { buscaProdutosDialogMultiDeposito } from "@/api/produtos/produtos.js";

export default {
  name: "DialogSearchProdutosMultiDeposito",

  props: {
    dialogSearchProdutosMultiDeposito: {
      type: Boolean,
      required: true,
    },
    lista_preco_id: {
      type: [Number, String],
    },
    moeda_id: {
      type: [Number, String],
    },
    deposito_id: {
      type: [Number, String],
    },
    deposito_descricao: {
      type: String,
    },
    typeComp: {
      type: String,
      default: "venda",
    },
    produto_sync: {
      type: Object,
    },
    tipo: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      produtos: [],
      totalProdutos: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15,
      },
      search: null,
      loadingSearch: false,
      error: false,
      produto: {
        id: null,
        descricao: null,
      },
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "id",
          value: "id",
          align: "center",
          width: "70px",
          divider: true,
        },
        {
          text: "referencia",
          value: "referencia",
          align: "center",
          width: "125px",
          divider: true,
        },
        {
          text: "descripcion",
          value: "descricao",
          divider: true,
        },

        {
          text: "D. Geral",
          value: "deposito1",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: "D. KM7",
          value: "deposito2",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: "D. GERARDO",
          value: "deposito3",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: "ATACADO",
          value: "preco_minimo",
          divider: true,
          width: "120px",
        },
        {
          text: "VAREJO",
          value: "preco2",
          divider: true,
          width: "120px",
        },
        {
          text: "PISCINEIRO",
          value: "preco3",
          divider: true,
          width: "120px",
        },
        {
          text: "ultimo custo",
          value: "ultimo_custo",
          divider: true,
          width: "120px",
          align: this.typeComp == "compra" ? "" : " d-none",
        },
        {
          text: "marca",
          value: "marca",
          width: "120px",
          divider: true,
        },
        {
          text: "cód. fábrica",
          value: "codigo_fabrica",
          width: "120px",
          divider: true,
        }
        // {
        //   text: "categoria",
        //   value: "categoria",
        //   width: "140px",
        //   divider: true,
        // },
        // {
        //   text: "fornecedor",
        //   value: "fornecedor",
        //   width: "140px",
        // },
      ];
    },
  },

  watch: {
    options: {
      handler() {
        this.getProdutos();
      },
      deep: true,
    },

    search() {
      if (!this.search || this.loading) return;
      this.buscaProduto();
    },
  },

  methods: {
    buscaProduto() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getProdutos();
      }, 500);
    },
    clearSearch() {
      this.search = null;
      this.produtos = [];
      (this.produto = {
        id: null,
        descricao: null,
      }),
        (this.error = false);
      this.getProdutos();
    },
    async getProdutos() {
      try {
        this.loadingSearch = true;
        this.produtos = [];
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let filtro = `?page=${page}`;
        filtro += `&sortBy=${sortBy}`;
        filtro += `&sortDesc=${sortDesc}`;
        filtro += `&itemsPerPage=${itemsPerPage}`;
        filtro += this.search ? `&search=${this.search}` : "";
        filtro += this.tipo ? `&tipo=${this.tipo}` : "";

        filtro += this.lista_preco_id
          ? `&lista_preco_id=${this.lista_preco_id}`
          : "";
        filtro += this.moeda_id ? `&moeda_id=${this.moeda_id}` : "";
        filtro += this.deposito_id ? `&deposito_id=${this.deposito_id}` : "";

        const response = await buscaProdutosDialogMultiDeposito(filtro);

        response.data[0].disabled = true;
        this.produtos = response.data;
        this.totalProdutos = response.total;
        if (this.options.itemsPerPage != Number(response.per_page)) {
          this.options.itemsPerPage = Number(response.per_page);
        }

        this.loadingSearch = false;
      } catch (error) {
        this.loadingSearch = false;
      }
    },

    row_classes(item) {
      let deposito_liberado = item.depositos_disponiveis.find(
        (deposito) => deposito == this.deposito_id
      );

      if (
        this.typeComp == "compra" ||
        this.typeComp == "orcamento" ||
        this.typeComp == "busca_deposito"
      ) {
        return ["green--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
      } else {
        if (!deposito_liberado && item.controle_estoque == 1) {
          return ["red--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
        }
        if (item.produto_kit && this.tipo == "simples") {
          return ["red--text", "row-disabled"]; //can also return multiple classes e.g ["orange","disabled"]
        }
        return ["green--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
      }
    },

    selectProduto(item) {
      let deposito_liberado = item.depositos_disponiveis.find(
        (deposito) => deposito == this.deposito_id
      );
      let qtde_deposito = 0;
      if (this.deposito_id == 1) {
        qtde_deposito = item.deposito1;
      }
      if (this.deposito_id == 2) {
        qtde_deposito = item.deposito2;
      }
      if (this.deposito_id == 3) {
        qtde_deposito = item.deposito3;
      }
      if (
        (Number(qtde_deposito) > 0 && deposito_liberado) ||
        (!item.controle_estoque && !item.produto_kit) ||
        (item.produto_kit && this.tipo !== "simples") ||
        this.typeComp == "compra" ||
        this.typeComp == "produtos" ||
        this.typeComp == "orcamento" ||
        this.typeComp == "fornecedor_servico"
      ) {
        this.produto.id = item.id;
        this.produto.descricao = item.marca
          ? item.marca + " | " + item.descricao
          : item.descricao;
        this.produto.disponivel_deposito1 = item.deposito1 - item.reservado_deposito_1 -item.reservado_transferencia_deposito1;
        this.produto.disponivel_deposito2 = item.deposito2 - item.reservado_deposito_2 -item.reservado_transferencia_deposito2;
        this.produto.disponivel_deposito3 = item.deposito3 - item.reservado_deposito_3 -item.reservado_transferencia_deposito3;
        this.produto.preco_minimo = item.preco_minimo;
        this.$emit("update:produto_sync", this.produto);
        this.$emit("update:dialogSearchProdutosMultiDeposito", false);
      }
    },
  },

  mounted() {
    if (this.typeComp == "venda" || this.typeComp == "orcamento") {
      if (this.deposito_id == 1) {
        this.options.sortBy = "deposito1";
      }
      if (this.deposito_id == 2) {
        this.options.sortBy = "deposito2";
      }
      if (this.deposito_id == 3) {
        this.options.sortBy = "deposito3";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .row-available {
  cursor: pointer;
}

::v-deep .row-disabled {
  opacity: 0.5;
}
</style>
